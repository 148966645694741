<template>
    <div>
        <Header />
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        <div class="container">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-5">
                                <div class="product-detail">
                                    <div class="tab-content" id="v-pills-tabContent">
                                        <div class="tab-pane fade active show" id="product-4" role="tabpanel">
                                            <div class="product-img">
                                                <img src="https://kmcorporate.com/wp-content/uploads/2021/06/KM100-rev.1-210609-DEF-1536x1044.jpg"
                                                    alt="img-4" class="img-fluid mx-auto d-block" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end product img -->
                            </div>
                            <div class="col-xl-7">
                                <div class="mt-4">


                                    <p style="font-size: 16px; color: black; font-weight: 400">
                                        The KM100 calibration tool is a device for setting the calibration height of
                                        bench crimping units and for those installed on automatic machines.
                                        The calibration height is the distance from the attachment base of the
                                        mini-applicator to the lower dead center of the hammer.
                                        The unit must be calibrated according to the type of mini-applicator to be
                                        installed. The calibration data are reported in the technical characteristics of
                                        the mini-applicator. </p>
                                </div>

                                <br />



                                <p style="font-size: 14px; color: black; font-weight: 400">

                                    Data and measurements are indicative and subject to change without notice. Some
                                    particular types of cables may not be machined even though they fall within the
                                    range of sections indicated. KM Corporate will still be happy to carry out tests and
                                    supply wire-worked samples
                                </p>
                            </div>
                        </div>
                        <!-- end row -->
                        <br />
                    
                       

                    </div>
                </div>
                <!-- end card -->
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
    components: {

        Header,
        Footer,


    },
    data() {
        return {
          
        };

    },

    methods: {
        
    }
};
</script>